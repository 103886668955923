import React from "react"
import { Modal } from "@material-ui/core"
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft"
import { IoClose } from "@react-icons/all-files/io5/IoClose"
import { StaticImage } from "gatsby-plugin-image"
import InputTexfieldRegex from "../forms/InputTexfieldRegex"
import { BsPersonFill } from "@react-icons/all-files/bs/BsPersonFill"
import { IoMdCard } from "@react-icons/all-files/io/IoMdCard"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine"
import firebase from "firebase"
import {
  getMobilePayments,
  saveNewCard,
  suscribe,
  getDiscountedPrice,
} from "../../api/content"
import { firebaseInit } from "../../config"
import Spinner from "../Spinner"
import SCButton from "../buttons/SCButton"
import {
  validateEmail,
  validateNames,
  validateNumbers,
} from "../forms/helper/validationsRegexFuncs.js"

const NewPaymentFormModal = ({
  show,
  onClose,
  back,
  cvvHelp,
  messageOk,
  reference,
}) => {
  const [cardInfo, setCardInfo] = React.useState({
    cardHolderName: "",
    cardNumber: "",
    cvv: "",
    expirationYear: 0,
    expirationMonth: 0,
  })

  const [paymentInfo, setPaymentInfo] = React.useState({
    paymentMethods: [],
  })

  const [expirationString, setExpirationString] = React.useState("")
  const [error, setError] = React.useState("")
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] =
    React.useState(false)
  const [saveMyCard, setSaveMyCard] = React.useState(false)
  const [token, setToken] = React.useState("")
  const [userId, setUserId] = React.useState("")
  const [cardDuplicate, setCardDuplicate] = React.useState(0)
  const [spinnerLoading, setSpinnerLoading] = React.useState(false)
  const [expirationHasSlash, setExpirationHasSlash] = React.useState(false)
  const [discountCode, setDiscountCode] = React.useState("")
  const [discountCodeSend, setDiscountCodeSend] = React.useState("")
  const [discountApplied, setDiscountApplied] = React.useState(false)
  const [precioDescuento, setPrecioDescuento] = React.useState(null)
  const [discountDetails, setDiscountDetails] = React.useState(null)
  const [feedbackMessage, setFeedbackMessage] = React.useState("")
  const [descriptionMessage, setDescriptionMessage] = React.useState("")
  const [feedbackColor, setFeedbackColor] = React.useState("")

  const numbers = /^\d+$/

  React.useEffect(() => {
    // Configuración inicial de Firebase
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid)
        user.getIdToken(true).then(token => {
          setToken(token)
          getMobilePayments(token)
            .then(result => {
              setPaymentInfo(result)
            })
            .catch(error => {
              console.log("datos sin cargar")
            })
        })
      } else {
        setToken(null)
      }
    })
  }, [])

  const updateProperty = (property, value) => {
    setCardInfo({ ...cardInfo, [property]: value })
  }

  const handleExpirationChange = value => {
    if (numbers.test(value) || value.includes("/")) {
      if (value.length < 2) {
        setExpirationString(value)
      } else {
        if (value.length <= 5) {
          let result = value
          if (!value.includes("/")) {
            result = [value.slice(0, 2), "/", value.slice(3)].join("")
            setExpirationHasSlash(!expirationHasSlash)
          }
          if (result.length == 3 && expirationHasSlash) {
            value = result.split("/").join("")
            result = result.split("/").join("")
            setExpirationHasSlash(!expirationHasSlash)
          }
          setExpirationString(result)
        }
      }
      let expirationMonth = value.length >= 2 ? parseInt(value.slice(0, 2)) : 0
      let expirationYear = value.length == 5 ? parseInt(value.slice(3, 5)) : 0
      setCardInfo({ ...cardInfo, expirationMonth, expirationYear })
    }
    if (value == "") {
      setExpirationString("")
    }
  }

  const handleChange = (property, value) => {
    if (numbers.test(value)) {
      setCardInfo({ ...cardInfo, [property]: value })
    } else if (value === "") {
      setCardInfo({ ...cardInfo, [property]: "" })
    }
  }

  const checkDiscount = () => {
    console.log(token)

    setSpinnerLoading(true)
    getDiscountedPrice(token, discountCode)
      .then(response => {
        if (response.success) {
          setDiscountApplied(true)
          setDiscountCodeSend(discountCode) // Guardar el código para usarlo en la transacción
          setPrecioDescuento(response.precioDescuento)
          setDiscountDetails({
            message: response.message,
            precioOriginal: response.precioOriginal,
            precioDescuento: response.precioDescuento,
            duracion: response.duracion,
            duracionPeridodo: response.duracionPeridodo,
            success: response.success,
            descripcion: response.descripcion,
          })
          setFeedbackMessage(response.message)
          setDescriptionMessage(response.descripcion)
          setFeedbackColor("text-green-500") // Verde para éxito
        } else {
          setDiscountApplied(false)
          setFeedbackMessage(
            response.message || "Código de descuento no válido"
          )
          setFeedbackColor("text-red-500") // Rojo para error
        }
        setSpinnerLoading(false)
      })
      .catch(error => {
        setDiscountApplied(false)
        setFeedbackMessage(error.message || "Código de descuento no válido")
        setFeedbackColor("text-red-500")
        setSpinnerLoading(false)
      })
  }

  const saveCard = () => {
    if (validate()) {
      setSpinnerLoading(true)
      saveNewCard(token, {
        number: cardInfo.cardNumber,
        name: cardInfo.cardHolderName,
        cvv: cardInfo.cvv,
        expirationMonth: cardInfo.expirationMonth,
        expirationYear: cardInfo.expirationYear,
      })
        .then(paymentMethod => {
          setCardInfo({
            cardHolderName: "",
            cardNumber: "",
            cvv: "",
            expirationYear: 0,
            expirationMonth: 0,
          })
          setExpirationString("")
          if (paymentMethod) {
            console.log(token)
            // Aplicar el código de descuento guardado en la transacción
            suscribe(token, paymentMethod.id, reference, discountCodeSend)
              .then(subscription => {
                messageOk(true)
                setSpinnerLoading(false)
              })
              .catch(error => {
                setError(error)
                setSpinnerLoading(false)
              })
          }
        })
        .catch(error => {
          setError(error)
          setSpinnerLoading(false)
        })
    }
  }

  const validate = () => {
    if (
      !cardInfo.cardHolderName ||
      !cardInfo.cardNumber ||
      !cardInfo.expirationMonth ||
      !cardInfo.cvv
    ) {
      setError("Por favor completa todos los campos")
      return false
    }
    setError("")
    return true
  }

  return (
    <Modal open={show} onClose={onClose}>
      <div className="bg-white text-sc-black relative mx-auto rounded-lg px-5 pt-8 pb-4 overflow-y-auto h-5/6 w-3/4 md:w-2/4 md:h-full bottom-10 mt-5 flex flex-col">
        <div className="flex justify-between">
          <div className="cursor-pointer" onClick={() => back()}>
            <FaArrowLeft size={25} color="#002821" />
          </div>
          <p className="text-lg font-bold ">Agregar nueva tarjeta</p>
          <div className="cursor-pointer" onClick={() => onClose()}>
            <IoClose size={25} color="#002821" />
          </div>
        </div>
        <div className="border-2 border-sc-primary pl-10 pr-2 pt-2 pb-1 ">
          <p className="text text-sm text-sc-black font-bold">
            Datos de la suscripción:
          </p>
          <div className="flex justify-between mb-2">
            <div className="">
              <p className="text text-sm text-sc-typo-titulo m-0 p-0">
                Suscripción digital Shift,
              </p>
              <p className="text text-sm text-sc-typo-titulo m-0 p-0">
                {precioDescuento && discountDetails ? (
                  <>
                    con el código de descuento el precio es de $
                    {precioDescuento} por
                    {" " + discountDetails.duracion}{" "}
                    {" " + discountDetails.duracionPeridodo}.
                  </>
                ) : (
                  "7 días gratis, después $99.00 mensuales."
                )}
              </p>
            </div>
            <div className="">
              <StaticImage
                width={96}
                src="../../images/logo_black.png"
                alt="Shift Logo"
              />
            </div>
          </div>
        </div>
        <div className="mt-6 mx-2">
          <p className="text-sc-black text-sm font-bold">Ingresar detalles</p>
          <div className="w-full my-1">
            <InputTexfieldRegex
              className="w-full"
              onChange={cardInfo.cardHolderName}
              setOnChange={updateProperty}
              validateFunc={validateNames}
              property={"cardHolderName"}
              placeHolder={"Nombre(s)"}
            >
              <BsPersonFill
                className="mb-0"
                size={20}
                style={{ color: "#6B6B6B" }}
              />
            </InputTexfieldRegex>
          </div>
          <div className="w-full my-1">
            <InputTexfieldRegex
              className="w-full"
              onChange={cardInfo.cardNumber}
              setOnChange={updateProperty}
              validateFunc={validateNumbers}
              property={"cardNumber"}
              placeHolder={"Número de la tarjeta"}
              type="int"
            >
              <IoMdCard style={{ color: "#6B6B6B" }} />
            </InputTexfieldRegex>
          </div>
          <div className="w-full flex justify-between my-1 ">
            <div className="w-5/12">
              <TextField
                className="w-full"
                placeholder="MM/AA"
                value={expirationString}
                size="small"
                onChange={e => {
                  handleExpirationChange(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoMdCard style={{ color: "#6B6B6B" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-5/12 ">
              <TextField
                className="w-full"
                placeholder="CVV"
                type="password"
                value={cardInfo.cvv}
                size="small"
                onChange={e => handleChange("cvv", e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoMdCard style={{ color: "#6B6B6B" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <a href="#" onClick={() => cvvHelp()}>
                        <RiErrorWarningLine
                          style={{ fontSize: "28px", cursor: "pointer" }}
                        />
                      </a>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="w-full my-1">
            <TextField
              className="w-full mb-4"
              placeholder="Código de descuento"
              value={discountCode}
              onChange={e => setDiscountCode(e.target.value)}
            />
            <SCButton
              className="mt-2 mb-4"
              children={"Aplicar Código"}
              onClick={() => checkDiscount()}
            />
          </div>
          {feedbackMessage && (
            <div className={`mt-4 text-center ${feedbackColor}`}>
              {feedbackMessage}
            </div>
          )}
          {feedbackMessage && descriptionMessage && (
            <div>{descriptionMessage}</div>
          )}
        </div>
        {spinnerLoading && (
          <div className="mt-4 flex justify-center items-center w-full">
            <Spinner size={25} />
          </div>
        )}
        {error && (
          <div className="mt-8 flex justify-center items-center w-full">
            <div className="text-red-500">{error}</div>
          </div>
        )}
        <div className="justify-center mx-14 mt-4">
          <SCButton
            className="mt-4"
            children={"PAGAR"}
            onClick={() => saveCard()}
          />
        </div>
        <div
          className="flex justify-center mt-4 cursor-pointer"
          onClick={() => onClose()}
        >
          Cancelar
        </div>
      </div>
    </Modal>
  )
}

export default NewPaymentFormModal
